import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

class ItemInfo extends Component {
  state = {};

  addZeroes(amount) {
    if (amount === 'N/A' || amount === undefined) return amount;
    else {
      // Cast as number
      let num = Number(amount);
      // If not a number, return 0
      if (isNaN(num)) {
        return 0;
      }
      // If there is no decimal, or the decimal is less than 2 digits, toFixed
      if (String(num).split('.').length < 2 || String(num).split('.')[1].length <= 2) {
        num = num.toFixed(2);
      }
      // Return the number
      return num;
    }
  }

  render() {
    return (
      <div className="itemInfoSection">
        <Row className="itemInfoRow" style={{ marginTop: '5px' }}>
          <Col md={4}>
            <div className="hide-mobile">
              <div className="itemInfo">
                <div className="itemInfoHeading">Target Price (FBA)</div>
                <div className="itemInfoValue">
                  <Row>
                    <Col md={this.props.asinData.winner.amazonLogData.price_target_ca_fba ? 6 : 12}>
                      {'price_target_ca_fba' in this.props.asinData.winner.amazonLogData && (
                        <span>
                          <img
                            style={{ marginTop: '-5px', width: '18px' }}
                            alt="USA"
                            src={require(`../assets/images/united-states.png`)}
                          />{' '}
                        </span>
                      )}
                      {this.props.asinData.winner.amazonLogData.price_target_fba
                        ? '$' + this.addZeroes(this.props.asinData.winner.amazonLogData.price_target_fba.toFixed(2))
                        : '$0'}
                      {/* {this.props.asinData.winner.amazonLogData.fba_fee_used_us && (
                      <span style={{ fontSize: "12px", marginLeft: "5px" }}>
                        (${this.addZeroes(this.props.asinData.winner.amazonLogData.fba_fee_used_us.toFixed(2))} fee)
                      </span>
                    )} */}
                    </Col>
                    {'price_target_ca_fba' in this.props.asinData.winner.amazonLogData && (
                      <Col md={6} className="itemInfoValueCA">
                        <img
                          style={{ marginTop: '-5px', width: '18px' }}
                          alt="CA"
                          src={require(`../assets/images/canada.png`)}
                        />{' '}
                        {this.props.asinData.winner.amazonLogData.price_target_ca_fba > 0
                          ? '$' +
                            this.addZeroes(this.props.asinData.winner.amazonLogData.price_target_ca_fba.toFixed(2))
                          : '$0'}
                      </Col>
                    )}
                  </Row>
                </div>

                <div className="itemInfoHeading marginT15">Target Price (MF)</div>
                <div className="itemInfoValue">
                  <Row>
                    <Col md={6}>
                      {'price_target_ca_mf' in this.props.asinData.winner.amazonLogData && (
                        <span>
                          <img
                            style={{ marginTop: '-5px', width: '18px' }}
                            alt="USA"
                            src={require(`../assets/images/united-states.png`)}
                          />{' '}
                        </span>
                      )}
                      {'price_target_mf' in this.props.asinData.winner.amazonLogData &&
                      this.props.asinData.winner.amazonLogData.price_target_mf > 0
                        ? '$' + this.addZeroes(this.props.asinData.winner.amazonLogData.price_target_mf.toFixed(2))
                        : '$0'}
                    </Col>
                    {'price_target_ca_mf' in this.props.asinData.winner.amazonLogData && (
                      <Col md={6} className="itemInfoValueCA">
                        <img
                          style={{ marginTop: '-5px', width: '18px' }}
                          alt="CA"
                          src={require(`../assets/images/canada.png`)}
                        />{' '}
                        {this.props.asinData.winner.amazonLogData.price_target_ca_mf > 0
                          ? '$' + this.addZeroes(this.props.asinData.winner.amazonLogData.price_target_ca_mf.toFixed(2))
                          : '$0'}
                      </Col>
                    )}
                  </Row>
                </div>
              </div>
            </div>
            <div className="show-mobile">
              <div className="itemInfo">
                <div className="itemInfoHeading">Used BB</div>
                <div className="itemInfoValue">
                  <Row>
                    <Col md={6}>
                      {'ca' in this.props.asinData.formatted && (
                        <span>
                          <img
                            style={{ marginTop: '-5px', width: '18px' }}
                            alt="USA"
                            src={require(`../assets/images/united-states.png`)}
                          />{' '}
                        </span>
                      )}
                      {this.props.asinData.formatted.usedBB > 0
                        ? '$' + this.addZeroes(this.props.asinData.formatted.usedBB)
                        : '--'}
                    </Col>

                    {'ca' in this.props.asinData.formatted && (
                      <Col md={6} className="itemInfoValueCA">
                        <span>
                          <img
                            style={{ marginTop: '-5px', width: '18px' }}
                            alt="CA"
                            src={require(`../assets/images/canada.png`)}
                          />{' '}
                          {this.props.asinData.formatted.ca.usedBB > 0
                            ? '$' + this.addZeroes(this.props.asinData.formatted.ca.usedBB)
                            : '--'}
                        </span>
                      </Col>
                    )}
                  </Row>
                </div>
                <div className="itemInfoHeading marginT15">New BB</div>
                <div className="itemInfoValue">
                  <Row>
                    <Col md={6}>
                      {'ca' in this.props.asinData.formatted && (
                        <span>
                          <img
                            style={{ marginTop: '-5px', width: '18px' }}
                            alt="USA"
                            src={require(`../assets/images/united-states.png`)}
                          />{' '}
                        </span>
                      )}
                      {this.props.asinData.formatted.newBB > 0
                        ? '$' + this.addZeroes(this.props.asinData.formatted.newBB)
                        : '--'}
                    </Col>

                    {'ca' in this.props.asinData.formatted && (
                      <Col md={6} className="itemInfoValueCA">
                        <span>
                          <img
                            style={{ marginTop: '-5px', width: '18px' }}
                            alt="CA"
                            src={require(`../assets/images/canada.png`)}
                          />{' '}
                          {this.props.asinData.formatted.ca.newBB > 0
                            ? '$' + this.addZeroes(this.props.asinData.formatted.ca.newBB)
                            : '--'}
                        </span>
                      </Col>
                    )}
                  </Row>
                </div>
                <div className="itemInfoHeading marginT15">Low New</div>
                <div className="itemInfoValue">
                  <Row>
                    <Col md={6}>
                      {'ca' in this.props.asinData.formatted && (
                        <span>
                          <img
                            style={{ marginTop: '-5px', width: '18px' }}
                            alt="USA"
                            src={require(`../assets/images/united-states.png`)}
                          />{' '}
                        </span>
                      )}
                      {this.props.asinData.formatted.newPrice > 0
                        ? '$' + this.addZeroes(this.props.asinData.formatted.newPrice)
                        : '--'}
                    </Col>

                    {'ca' in this.props.asinData.formatted && (
                      <Col md={6} className="itemInfoValueCA">
                        <span>
                          <img
                            style={{ marginTop: '-5px', width: '18px' }}
                            alt="CA"
                            src={require(`../assets/images/canada.png`)}
                          />{' '}
                          {this.props.asinData.formatted.ca.newPrice > 0
                            ? '$' + this.addZeroes(this.props.asinData.formatted.ca.newPrice)
                            : '--'}
                        </span>
                      </Col>
                    )}
                  </Row>
                </div>
                <div className="itemInfoHeading marginT15">Amazon Price</div>
                <div className="itemInfoValue">
                  <Row>
                    <Col md={6}>
                      {'ca' in this.props.asinData.formatted && (
                        <span>
                          <img
                            style={{ marginTop: '-5px', width: '18px' }}
                            alt="USA"
                            src={require(`../assets/images/united-states.png`)}
                          />{' '}
                        </span>
                      )}
                      {this.props.asinData.formatted.amazonPrice > 0
                        ? '$' + this.addZeroes(this.props.asinData.formatted.amazonPrice)
                        : '--'}
                    </Col>

                    {'ca' in this.props.asinData.formatted && (
                      <Col md={6} className="itemInfoValueCA">
                        <span>
                          <img
                            style={{ marginTop: '-5px', width: '18px' }}
                            alt="CA"
                            src={require(`../assets/images/canada.png`)}
                          />{' '}
                          {this.props.asinData.formatted.ca.amazonPrice > 0
                            ? '$' + this.addZeroes(this.props.asinData.formatted.ca.amazonPrice)
                            : '--'}
                        </span>
                      </Col>
                    )}
                  </Row>
                </div>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="show-mobile">
              <div className="itemInfo">
                <div className="itemInfoHeading">Target Price (FBA)</div>
                <div className="itemInfoValue">
                  <Row>
                    <Col md={this.props.asinData.winner.amazonLogData.price_target_ca_fba ? 6 : 12}>
                      {'price_target_ca_fba' in this.props.asinData.winner.amazonLogData && (
                        <span>
                          <img
                            style={{ marginTop: '-5px', width: '18px' }}
                            alt="USA"
                            src={require(`../assets/images/united-states.png`)}
                          />{' '}
                        </span>
                      )}
                      {this.props.asinData.winner.amazonLogData.price_target_fba
                        ? '$' + this.addZeroes(this.props.asinData.winner.amazonLogData.price_target_fba.toFixed(2))
                        : '$0'}
                      {/* {this.props.asinData.winner.amazonLogData.fba_fee_used_us && (
                      <span style={{ fontSize: "12px", marginLeft: "5px" }}>
                        (${this.addZeroes(this.props.asinData.winner.amazonLogData.fba_fee_used_us.toFixed(2))} fee)
                      </span>
                    )} */}
                    </Col>
                    {'price_target_ca_fba' in this.props.asinData.winner.amazonLogData && (
                      <Col md={6} className="itemInfoValueCA">
                        <img
                          style={{ marginTop: '-5px', width: '18px' }}
                          alt="CA"
                          src={require(`../assets/images/canada.png`)}
                        />{' '}
                        {this.props.asinData.winner.amazonLogData.price_target_ca_fba > 0
                          ? '$' +
                            this.addZeroes(this.props.asinData.winner.amazonLogData.price_target_ca_fba.toFixed(2))
                          : '$0'}
                      </Col>
                    )}
                  </Row>
                </div>

                <div className="itemInfoHeading marginT15">Target Price (MF)</div>
                <div className="itemInfoValue">
                  <Row>
                    <Col md={6}>
                      {'price_target_ca_mf' in this.props.asinData.winner.amazonLogData && (
                        <span>
                          <img
                            style={{ marginTop: '-5px', width: '18px' }}
                            alt="USA"
                            src={require(`../assets/images/united-states.png`)}
                          />{' '}
                        </span>
                      )}
                      {'price_target_mf' in this.props.asinData.winner.amazonLogData &&
                      this.props.asinData.winner.amazonLogData.price_target_mf > 0
                        ? '$' + this.addZeroes(this.props.asinData.winner.amazonLogData.price_target_mf.toFixed(2))
                        : '$0'}
                    </Col>
                    {'price_target_ca_mf' in this.props.asinData.winner.amazonLogData && (
                      <Col md={6} className="itemInfoValueCA">
                        <img
                          style={{ marginTop: '-5px', width: '18px' }}
                          alt="CA"
                          src={require(`../assets/images/canada.png`)}
                        />{' '}
                        {this.props.asinData.winner.amazonLogData.price_target_ca_mf > 0
                          ? '$' + this.addZeroes(this.props.asinData.winner.amazonLogData.price_target_ca_mf.toFixed(2))
                          : '$0'}
                      </Col>
                    )}
                  </Row>
                </div>
              </div>
            </div>
            <div className="hide-mobile">
              <div className="itemInfo">
                <div className="itemInfoHeading">List Price</div>
                <div className="itemInfoValue">
                  <Row>
                    <Col md={6}>
                      {'ca' in this.props.asinData.formatted && (
                        <span>
                          <img
                            style={{ marginTop: '-5px', width: '18px' }}
                            alt="USA"
                            src={require(`../assets/images/united-states.png`)}
                          />{' '}
                        </span>
                      )}
                      {this.props.asinData.formatted.listPrice !== 'N/A'
                        ? `$${this.props.asinData.formatted.listPrice}`
                        : `--`}
                    </Col>

                    {'ca' in this.props.asinData.formatted && (
                      <Col md={6} className="itemInfoValueCA">
                        <span>
                          <img
                            style={{ marginTop: '-5px', width: '18px' }}
                            alt="CA"
                            src={require(`../assets/images/canada.png`)}
                          />{' '}
                          {this.props.asinData.formatted.ca.listPrice
                            ? `$${this.props.asinData.formatted.ca.listPrice}`
                            : `--`}
                        </span>
                      </Col>
                    )}
                  </Row>
                </div>

                <div className="itemInfoHeading marginT15">Weight</div>
                <div className="itemInfoValue">{this.props.asinData.formatted.itemWeight}</div>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="itemInfo">
              <div className="itemInfoHeading">Rank</div>
              <div className="itemInfoValue">
                <Row>
                  <Col md={6}>
                    {'ca' in this.props.asinData.formatted && (
                      <span>
                        <img
                          style={{ marginTop: '-5px', width: '18px' }}
                          alt="USA"
                          src={require(`../assets/images/united-states.png`)}
                        />{' '}
                      </span>
                    )}
                    {this.props.asinData.formatted.salesrank}
                  </Col>

                  {'ca' in this.props.asinData.formatted && (
                    <Col md={6} className="itemInfoValueCA">
                      <span>
                        <img
                          style={{ marginTop: '-5px', width: '18px' }}
                          alt="CA"
                          src={require(`../assets/images/canada.png`)}
                        />{' '}
                        {this.props.asinData.formatted.ca.salesrank}
                      </span>
                    </Col>
                  )}
                </Row>
              </div>
              <div className="itemInfoHeading marginT15">eScore</div>
              <div className="itemInfoValue">
                <Row>
                  <Col md={6}>
                    {'ca' in this.props.asinData.formatted && (
                      <span>
                        <img
                          style={{ marginTop: '-5px', width: '18px' }}
                          alt="USA"
                          src={require(`../assets/images/united-states.png`)}
                        />{' '}
                      </span>
                    )}
                    {this.props.asinData.formatted.escore}
                  </Col>

                  {'ca' in this.props.asinData.formatted && (
                    <Col md={6} className="itemInfoValueCA">
                      <span>
                        <img
                          style={{ marginTop: '-5px', width: '18px' }}
                          alt="CA"
                          src={require(`../assets/images/canada.png`)}
                        />{' '}
                        {this.props.asinData.formatted.ca.escore}
                      </span>
                    </Col>
                  )}
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="itemInfoRow">
          <Col md={4}>
            <div className="itemInfo">
              {/* <div className="itemInfoHeading">{this.props.asinData.meta.offer_count_used} Used Offers</div> */}

              <div className="itemInfoHeading">
                {'offersCA' in this.props.asinData ? (
                  <span>
                    {this.props.asinData.meta.total_used_ca} Used Offers
                    {/* {this.props.asinData.offers.used.length} US & {this.props.asinData.offersCA.used.length} CA Used
                    Offers */}
                  </span>
                ) : (
                  <span>
                    {this.props.asinData.meta.total_used_us
                      ? this.props.asinData.meta.total_used_us
                      : this.props.asinData.offers.count
                      ? this.props.asinData.offers.count.mf_used
                      : ''}{' '}
                    Used Offers
                  </span>
                )}
              </div>
              <Row>
                <Col md={6}>
                  <div className="itemInfoValue">
                    {'offersCA' in this.props.asinData && (
                      <div className="countryTag">
                        <img
                          style={{ marginTop: '-5px', width: '18px' }}
                          alt="CA"
                          src={require(`../assets/images/united-states.png`)}
                        />{' '}
                        USA ({this.props.asinData.offers.used.length})
                      </div>
                    )}
                    {this.props.asinData.offers.used.map((usedOffer, _index) => (
                      <div key={'used_offers_us_' + _index}>
                        ${this.addZeroes(usedOffer)}{' '}
                        {Number(usedOffer) == Number(this.props.asinData.prices.lowest_collectible) ? (
                          <span>(C)</span>
                        ) : (
                          ''
                        )}
                      </div>
                    ))}
                    {/* {this.props.asinData.meta.total_used_us !== this.props.asinData.offers.used.length && (
                      <div key={"used_offer_us_elipp"}>...</div>
                    )} */}
                  </div>
                </Col>
                <Col md={6}>
                  {'offersCA' in this.props.asinData && (
                    <div className="itemInfoValueCA">
                      <div className="countryTag">
                        <img
                          style={{ marginTop: '-5px', width: '18px' }}
                          alt="CA"
                          src={require(`../assets/images/canada.png`)}
                        />{' '}
                        CA ({this.props.asinData.offersCA.used.length})
                      </div>
                      {this.props.asinData.offersCA.used.map((usedOffer, _index) => (
                        <div key={'used_offers_ca_' + _index}>${this.addZeroes(usedOffer)}</div>
                      ))}
                    </div>
                  )}
                </Col>
              </Row>
              {/* <div className="itemInfoValue">
                {this.props.asinData.offers.used.map((usedOffer, _index) => (
                  <div key={"up" + _index}>${this.addZeroes(usedOffer)}</div>
                ))}
              </div> */}
            </div>
          </Col>
          <Col md={4}>
            <div className="itemInfo">
              <div className="itemInfoHeading">
                {'offersCA' in this.props.asinData ? (
                  <span>FBA Offers</span>
                ) : (
                  <span>
                    {this.props.asinData.offers.count
                      ? this.props.asinData.offers.count.fba_used
                      : this.props.asinData.offers.fba.length}{' '}
                    FBA Offers
                  </span>
                )}
              </div>

              <Row>
                <Col md={6}>
                  <div className="itemInfoValue">
                    {'offersCA' in this.props.asinData && (
                      <div className="countryTag">
                        <img
                          style={{ marginTop: '-5px', width: '18px' }}
                          alt="CA"
                          src={require(`../assets/images/united-states.png`)}
                        />{' '}
                        USA ({this.props.asinData.offers.fba.length})
                      </div>
                    )}
                    {this.props.asinData.offers.fba.map((fbaOffer, _index) => (
                      <div key={'fo' + _index}>
                        ${this.addZeroes(fbaOffer)}{' '}
                        {fbaOffer == this.props.asinData.prices.lowest_collectible_fba ? <span>(C)</span> : ''}
                      </div>
                    ))}
                  </div>
                </Col>
                <Col md={6}>
                  {'offersCA' in this.props.asinData && (
                    <div className="itemInfoValueCA">
                      <div className="countryTag">
                        <img
                          style={{ marginTop: '-5px', width: '18px' }}
                          alt="CA"
                          src={require(`../assets/images/canada.png`)}
                        />{' '}
                        CA ({this.props.asinData.offersCA.fba.length})
                      </div>
                      {this.props.asinData.offersCA.fba.map((fbaOffer, _index) => (
                        <div key={'fo' + _index}>${this.addZeroes(fbaOffer)}</div>
                      ))}
                    </div>
                  )}
                </Col>
              </Row>

              {/* <div className="itemInfoValue">
                {this.props.asinData.offers.fba.map((fbaOffer, _index) => (
                  <div key={"fo" + _index}>${this.addZeroes(fbaOffer)}</div>
                ))}
              </div> */}
            </div>
          </Col>
          <Col md={4}>
            <div className="show-mobile">
              <div className="itemInfo">
                <div className="itemInfoHeading">List Price</div>
                <div className="itemInfoValue">
                  <Row>
                    <Col md={6}>
                      {'ca' in this.props.asinData.formatted && (
                        <span>
                          <img
                            style={{ marginTop: '-5px', width: '18px' }}
                            alt="USA"
                            src={require(`../assets/images/united-states.png`)}
                          />{' '}
                        </span>
                      )}
                      {this.props.asinData.formatted.listPrice !== 'N/A'
                        ? `$${this.props.asinData.formatted.listPrice}`
                        : `--`}
                    </Col>

                    {'ca' in this.props.asinData.formatted && (
                      <Col md={6} className="itemInfoValueCA">
                        <span>
                          <img
                            style={{ marginTop: '-5px', width: '18px' }}
                            alt="CA"
                            src={require(`../assets/images/canada.png`)}
                          />{' '}
                          {this.props.asinData.formatted.ca.listPrice
                            ? `$${this.props.asinData.formatted.ca.listPrice}`
                            : `--`}
                        </span>
                      </Col>
                    )}
                  </Row>
                </div>

                {/* <div className="itemInfoHeading">Trade-in Value</div>
                <div className="itemInfoValue">
                  <Row>
                    <Col md={6}>
                      {'ca' in this.props.asinData.formatted && (
                        <span>
                          <img
                            style={{ marginTop: '-5px', width: '18px' }}
                            alt="USA"
                            src={require(`../assets/images/united-states.png`)}
                          />{' '}
                        </span>
                      )}
                      ${this.props.asinData.formatted.tradeIn}
                    </Col>

                    {'ca' in this.props.asinData.formatted && (
                      <Col md={6} className="itemInfoValueCA">
                        <span>
                          <img
                            style={{ marginTop: '-5px', width: '18px' }}
                            alt="CA"
                            src={require(`../assets/images/canada.png`)}
                          />{' '}
                          ${this.props.asinData.formatted.ca.tradeIn}
                        </span>
                      </Col>
                    )}
                  </Row>
                </div> */}

                <div className="itemInfoHeading marginT15">Weight</div>
                <div className="itemInfoValue">{this.props.asinData.formatted.itemWeight}</div>
              </div>
            </div>
            <div className="hide-mobile">
              <div className="itemInfo">
                <div className="itemInfoHeading">Used BB</div>
                <div className="itemInfoValue">
                  <Row>
                    <Col md={6}>
                      {'ca' in this.props.asinData.formatted && (
                        <span>
                          <img
                            style={{ marginTop: '-5px', width: '18px' }}
                            alt="USA"
                            src={require(`../assets/images/united-states.png`)}
                          />{' '}
                        </span>
                      )}
                      {this.props.asinData.formatted.usedBB > 0
                        ? '$' + this.addZeroes(this.props.asinData.formatted.usedBB)
                        : '--'}
                    </Col>

                    {'ca' in this.props.asinData.formatted && (
                      <Col md={6} className="itemInfoValueCA">
                        <span>
                          <img
                            style={{ marginTop: '-5px', width: '18px' }}
                            alt="CA"
                            src={require(`../assets/images/canada.png`)}
                          />{' '}
                          {this.props.asinData.formatted.ca.usedBB > 0
                            ? '$' + this.addZeroes(this.props.asinData.formatted.ca.usedBB)
                            : '--'}
                        </span>
                      </Col>
                    )}
                  </Row>
                </div>
                <div className="itemInfoHeading marginT15">New BB</div>
                <div className="itemInfoValue">
                  <Row>
                    <Col md={6}>
                      {'ca' in this.props.asinData.formatted && (
                        <span>
                          <img
                            style={{ marginTop: '-5px', width: '18px' }}
                            alt="USA"
                            src={require(`../assets/images/united-states.png`)}
                          />{' '}
                        </span>
                      )}
                      {this.props.asinData.formatted.newBB > 0
                        ? '$' + this.addZeroes(this.props.asinData.formatted.newBB)
                        : '--'}
                    </Col>

                    {'ca' in this.props.asinData.formatted && (
                      <Col md={6} className="itemInfoValueCA">
                        <span>
                          <img
                            style={{ marginTop: '-5px', width: '18px' }}
                            alt="CA"
                            src={require(`../assets/images/canada.png`)}
                          />{' '}
                          {this.props.asinData.formatted.ca.newBB > 0
                            ? '$' + this.addZeroes(this.props.asinData.formatted.ca.newBB)
                            : '--'}
                        </span>
                      </Col>
                    )}
                  </Row>
                </div>
                <div className="itemInfoHeading marginT15">Low New</div>
                <div className="itemInfoValue">
                  <Row>
                    <Col md={6}>
                      {'ca' in this.props.asinData.formatted && (
                        <span>
                          <img
                            style={{ marginTop: '-5px', width: '18px' }}
                            alt="USA"
                            src={require(`../assets/images/united-states.png`)}
                          />{' '}
                        </span>
                      )}
                      {this.props.asinData.formatted.newPrice > 0
                        ? '$' + this.addZeroes(this.props.asinData.formatted.newPrice)
                        : '--'}
                    </Col>

                    {'ca' in this.props.asinData.formatted && (
                      <Col md={6} className="itemInfoValueCA">
                        <span>
                          <img
                            style={{ marginTop: '-5px', width: '18px' }}
                            alt="CA"
                            src={require(`../assets/images/canada.png`)}
                          />{' '}
                          {this.props.asinData.formatted.ca.newPrice > 0
                            ? '$' + this.addZeroes(this.props.asinData.formatted.ca.newPrice)
                            : '--'}
                        </span>
                      </Col>
                    )}
                  </Row>
                </div>
                <div className="itemInfoHeading marginT15">Amazon Price</div>
                <div className="itemInfoValue">
                  <Row>
                    <Col md={6}>
                      {'ca' in this.props.asinData.formatted && (
                        <span>
                          <img
                            style={{ marginTop: '-5px', width: '18px' }}
                            alt="USA"
                            src={require(`../assets/images/united-states.png`)}
                          />{' '}
                        </span>
                      )}
                      {this.props.asinData.formatted.amazonPrice > 0
                        ? '$' + this.addZeroes(this.props.asinData.formatted.amazonPrice)
                        : '--'}
                    </Col>

                    {'ca' in this.props.asinData.formatted && (
                      <Col md={6} className="itemInfoValueCA">
                        <span>
                          <img
                            style={{ marginTop: '-5px', width: '18px' }}
                            alt="CA"
                            src={require(`../assets/images/canada.png`)}
                          />{' '}
                          {this.props.asinData.formatted.ca.amazonPrice > 0
                            ? '$' + this.addZeroes(this.props.asinData.formatted.ca.amazonPrice)
                            : '--'}
                        </span>
                      </Col>
                    )}
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ItemInfo;
