import React, { Component } from 'react';
import { logoWidth } from '../Config';
import { Container, ProgressBar, Spinner } from 'react-bootstrap';
import '../assets/style/signup.css';
import { submitSignup, validateMWS, validateInviteToken } from '../includes/HelperSignup';

import Step4Trigger3 from './onboarding/Step4Trigger3';
import StepIntroVideo from './onboarding/StepIntroVideo';
import StepSingleInput from './onboarding/StepSingleInput';
import StepMinProfitInput from './onboarding/StepMinProfitInput';
import StepBinary from './onboarding/StepBinary';
import StepMultipleChoices from './onboarding/StepMultipleChoices';
import StepAmazonCredentials from './onboarding/StepAmazonCredentials';
import StepInfo from './onboarding/StepInfo';
//import StepTeamInfo from "./onboarding/StepTeamInfo";
import StepPassword from './onboarding/StepPassword';
import StepDoubleInput from './onboarding/StepDoubleInput';
import StepPayment from './onboarding/StepPayment';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
const stripePromise = loadStripe(
  'pk_live_51IBKtGC7FxMC3n4vimf9s7YT4uZU8plqGBord2VUS7AtT2D75noDsFieCPocMz0HJKofyZWgwkHT44F7cZCZkiWq00hNTux3IB'
);

class Signup extends Component {
  state = {
    firstName: '',
    lastName: '',
    teamEmail: '',
    isFBA: null,
    isMF: null,
    setFBAvsMF: '',
    FBALowerRoute: '',
    MFLowerRoute: '',
    inboundShippingCost: '',
    isMediaMail: null,
    activeVendorsList: [],
    activeBSVendorsList: [],
    wholesaleAboveAmazon: null,
    wholesaleAboveAmazonMinProfit: '',
    bookscouterAboveAmazon: null,
    bookscouterAboveAmazonMinProfit: '',
    emailSBYB: '',

    minProfitSellEveryday: '',
    isAlwaysRejectSellEveryday: false,

    minProfitSellFewTimesAWeek: '',
    isAlwaysRejectSellFewTimesAWeek: false,

    minProfitSellOnceAWeek: '',
    isAlwaysRejectSellOnceAWeek: false,

    minProfitSellEveryOtherAWeek: '',
    isAlwaysRejectSellEveryOtherAWeek: false,

    minProfitSellOnceAMonth: '',
    isAlwaysRejectSellOnceAMonth: false,

    minProfitSellEvery2or3Months: '',
    isAlwaysRejectSellEvery2or3Months: false,

    minProfitSellEvery6Months: '',
    isAlwaysRejectSellEvery6Months: false,

    minProfitSellOnceAYear: '',
    isAlwaysRejectSellOnceAYear: false,

    targetPriceNoOffers: '',
    isFBALowerThanMF: null,
    isMFLowerThanFBA: null,

    mwsSellerId: '',
    mwsAuthToken: '',

    appToken: '',

    userPassword: '',
    userPassword2: '',

    stepSize: 1,
    filledSteps: 1,
    currentStep: 1,
    totalSteps: 34,
    validatingMWS: false,
    createAccountInProgress: false,
    createAccountError: '',
    errorMWS: '',

    validatingToken: true,
    invalidToken: true,
    signupInProgress: false,
    user_id: -1,
    user_email: '',
    team_name: '',
  };

  constructor() {
    super();
    this.gotoNextScreen = this.gotoNextScreen.bind(this);
    this.getCurrentStep = this.getCurrentStep.bind(this);
    this.updateWholesaleVendorsList = this.updateWholesaleVendorsList.bind(this);
    this.updateBSVendorsList = this.updateBSVendorsList.bind(this);
    this.gotoPreviousScreen = this.gotoPreviousScreen.bind(this);
    this.validateMWSAndGotoNextScreen = this.validateMWSAndGotoNextScreen.bind(this);
    this.finishSignup = this.finishSignup.bind(this);
  }

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let appToken = params.get('token');

    this.setState({ appToken });

    this.validateInvitation(appToken);
  }

  async validateInvitation(appToken) {
    // Verify Token
    this.setState({ validatingToken: true });
    const response = await validateInviteToken(appToken);
    if (undefined !== response && 'status' in response && response.status === true) {
      this.setState({
        invalidToken: false,
        user_id: response.user_id,
        user_email: response.user_email,
        team_name: response.team_name,
      });
    } else {
      this.setState({ invalidToken: true });
    }
    this.setState({ validatingToken: false });
    console.log(response.status);
  }

  async finishSignup() {
    console.log(`Finishing Signup`);

    this.setState({ signupInProgress: true });
    const response = await submitSignup(JSON.stringify(this.state));
    console.log(response);

    this.setState({
      stepSize: 1,
      currentStep: this.state.currentStep + 1,
      signupInProgress: false,
    });
    if (this.state.currentStep >= this.state.filledSteps) this.setState({ filledSteps: this.state.filledSteps + 1 });
  }

  async validateMWSAndGotoNextScreen() {
    this.setState({ validatingMWS: true, errorMWS: '' });
    const response = await validateMWS(this.state.mwsSellerId, this.state.mwsAuthToken);
    if (undefined !== response && 'status' in response && response.status === true) {
      this.gotoNextScreen();
    } else {
      this.setState({ errorMWS: 'Invalid MWS Credentials' });
    }
    this.setState({ validatingMWS: false });
    console.log(response.status);
  }

  async gotoNextScreen() {
    let stepSize = 1;

    if (this.state.currentStep === 7 && this.state.isFBALowerThanMF === false) stepSize = 2;
    if (this.state.currentStep === 9 && this.state.isMFLowerThanFBA === false) stepSize = 2;
    if (this.state.currentStep === 19 && this.state.bookscouterAboveAmazon === false) stepSize = 2;
    if (this.state.currentStep === 15 && !this.state.activeVendorsList.includes('SBYB (SellBackYourBooks)'))
      stepSize = 2;
    if (this.state.currentStep === 17 && this.state.wholesaleAboveAmazon === false) stepSize = 2;
    if (this.state.currentStep === 6 && this.state.setFBAvsMF === 'mf') stepSize = 3;
    if (this.state.currentStep === 8 && this.state.setFBAvsMF === 'fba') stepSize = 3;
    if (this.state.currentStep === 19 && this.state.activeBSVendorsList.includes('None')) stepSize = 3;
    if (this.state.currentStep === 20 && this.state.bookscouterAboveAmazon === false) stepSize = 2;
    if (this.state.currentStep === 8 && this.state.isFBALowerThanMF === true) stepSize = 3;

    if (this.state.currentStep === 12 && this.state.isMF === false) {
      this.setState({ isMediaMail: true });
      stepSize = 2;
    }

    if (this.state.currentStep === 5 && (this.state.isFBA === false || this.state.isMF === false)) stepSize = 6;

    this.setState({
      stepSize,
      currentStep: this.state.currentStep + stepSize,
    });
    if (this.state.currentStep >= this.state.filledSteps)
      this.setState({ filledSteps: this.state.filledSteps + stepSize });
  }

  gotoPreviousScreen() {
    this.setState(
      {
        currentStep: this.state.currentStep - this.state.stepSize,
      },
      () => {
        if (this.state.stepSize > 1) this.setState({ stepSize: 1 });
      }
    );
  }

  updateWholesaleVendorsList(e) {
    if (e.target.value === 'None' && e.target.checked) {
      this.setState({ activeVendorsList: ['None'] });
    } else {
      const activeVendorsList = [...this.state.activeVendorsList];
      const vIndex = activeVendorsList.indexOf(e.target.value);

      if (e.target.checked) activeVendorsList.push(e.target.value);
      else activeVendorsList.splice(vIndex, 1);

      this.setState({
        activeVendorsList,
      });
    }
  }

  updateBSVendorsList(e) {
    if (e.target.value === 'None' && e.target.checked) {
      this.setState({ activeBSVendorsList: ['None'] });
    } else {
      const activeBSVendorsList = [...this.state.activeBSVendorsList];
      const vIndex = activeBSVendorsList.indexOf(e.target.value);

      if (e.target.checked) activeBSVendorsList.push(e.target.value);
      else activeBSVendorsList.splice(vIndex, 1);

      this.setState({
        activeBSVendorsList,
      });
    }
  }

  getCurrentStep() {
    switch (this.state.currentStep) {
      case 1:
        return (
          <StepIntroVideo
            lastStep={true}
            gotoNextScreen={this.gotoNextScreen}
            gotoPreviousScreen={this.gotoPreviousScreen}
          />
        );
      case 2:
        return (
          <StepDoubleInput
            heading="Let's get your account set up"
            smallMessage="Enter your First &amp; Last Name to get started. This is used to contact you about your account."
            gotoNextScreen={this.gotoNextScreen}
            gotoPreviousScreen={this.gotoPreviousScreen}
            inputVal1={this.state.firstName}
            inputVal2={this.state.lastName}
            inputType1="text"
            inputType2="text"
            inputLabel1="First Name"
            inputLabel2="Last Name"
            placeholderString1="e.g. Marco"
            placeholderString2="e.g. Polo"
            exportVal1={(firstName) => this.setState({ firstName })}
            exportVal2={(lastName) => this.setState({ lastName })}
          />
        );
      case 3:
        return (
          <StepInfo
            gotoNextScreen={this.gotoNextScreen}
            gotoPreviousScreen={this.gotoPreviousScreen}
            infoMessage="Great! Now let's begin building out your triggers."
          />
        );
      case 4:
        return (
          <StepBinary
            heading="Triggers Setup"
            message="Do you sell FBA (Fulfillment by Amazon)?"
            gotoNextScreen={this.gotoNextScreen}
            gotoPreviousScreen={this.gotoPreviousScreen}
            inputVal={this.state.isFBA}
            exportBinary={(isFBA) => this.setState({ isFBA })}
          />
        );
      case 5:
        return (
          <StepBinary
            heading="Triggers Setup"
            message="Do you sell Merchant Fulfilled?"
            gotoPreviousScreen={this.gotoPreviousScreen}
            gotoNextScreen={this.gotoNextScreen}
            inputVal={this.state.isMF}
            exportBinary={(isMF) => this.setState({ isMF })}
          />
        );
      case 6:
        return (
          <Step4Trigger3
            gotoNextScreen={this.gotoNextScreen}
            gotoPreviousScreen={this.gotoPreviousScreen}
            inputVal={this.state.setFBAvsMF}
            setFBAvsMF={(setFBAvsMF) => this.setState({ setFBAvsMF })}
          />
        );
      case 7:
        return (
          <StepBinary
            heading="Triggers Setup"
            message="If the profit for FBA was lower than MF, would you still select FBA as the winner?"
            gotoPreviousScreen={this.gotoPreviousScreen}
            gotoNextScreen={this.gotoNextScreen}
            inputVal={this.state.isFBALowerThanMF}
            exportBinary={(isFBALowerThanMF) => this.setState({ isFBALowerThanMF })}
          />
        );
      case 8:
        return (
          <StepSingleInput
            heading="Triggers Setup"
            message="How much lower profit would you still route it to FBA?"
            gotoNextScreen={this.gotoNextScreen}
            gotoPreviousScreen={this.gotoPreviousScreen}
            inputVal={this.state.FBALowerRoute}
            exportVal={(FBALowerRoute) => this.setState({ FBALowerRoute })}
          />
        );
      case 9:
        return (
          <StepBinary
            heading="Triggers Setup"
            message="If the profit for MF was lower than FBA, would you still select MF as the winner?"
            gotoPreviousScreen={this.gotoPreviousScreen}
            gotoNextScreen={this.gotoNextScreen}
            inputVal={this.state.isMFLowerThanFBA}
            exportBinary={(isMFLowerThanFBA) => this.setState({ isMFLowerThanFBA })}
          />
        );
      case 10:
        return (
          <StepSingleInput
            heading="Triggers Setup"
            message="How much lower profit would you still route it to MF?"
            gotoNextScreen={this.gotoNextScreen}
            gotoPreviousScreen={this.gotoPreviousScreen}
            inputVal={this.state.MFLowerRoute}
            exportVal={(MFLowerRoute) => this.setState({ MFLowerRoute })}
          />
        );

      case 11:
        return (
          <StepInfo
            gotoNextScreen={this.gotoNextScreen}
            gotoPreviousScreen={this.gotoPreviousScreen}
            infoMessage="Nice work! Now, let's get a handle on your expenses."
          />
        );
      case 12:
        return (
          <StepSingleInput
            heading="EXPENSES"
            message="What are your inbound shipping costs per pound when sending FBA items to Amazon?"
            smallMessage="(If you ship individual boxes, it’s usually $0.25 a pound.  If you ship pallets, it’s usually around $0.10 cents a pound.)"
            gotoNextScreen={this.gotoNextScreen}
            gotoPreviousScreen={this.gotoPreviousScreen}
            inputVal={this.state.inboundShippingCost}
            exportVal={(inboundShippingCost) => this.setState({ inboundShippingCost })}
          />
        );
      case 13:
        return (
          <StepBinary
            heading="EXPENSES"
            message="Do you use Media Mail for MF shipping?"
            gotoNextScreen={this.gotoNextScreen}
            gotoPreviousScreen={this.gotoPreviousScreen}
            inputVal={this.state.isMediaMail}
            exportBinary={(isMediaMail) => this.setState({ isMediaMail })}
          />
        );
      case 14:
        return (
          <StepInfo
            gotoNextScreen={this.gotoNextScreen}
            gotoPreviousScreen={this.gotoPreviousScreen}
            infoMessage="You're almost done! Just a few questions about setting up your wholesale providers."
          />
        );
      case 15:
        return (
          <StepMultipleChoices
            gotoNextScreen={this.gotoNextScreen}
            gotoPreviousScreen={this.gotoPreviousScreen}
            heading="Which wholesale vendors would you like to use?"
            exportUpdatedList={this.updateWholesaleVendorsList}
            checkedList={this.state.activeVendorsList}
            smallMessage="The following wholesale vendors are natively integrated into our system.  That means it’s a “one-touch solution” where if one of these vendors is the winner, you can throw the book in a gaylord or a box and we’ll communicate directly with the vendor on your behalf.  No need to re-scan these books back into a secondary scan screen!"
            options={[
              'SBYB (SellBackYourBooks)',
              'UBB (UsedBookBuyer)',
              'Ziffit - Coming Soon!',
              'BRB (BlueRocketBooks)',
              'BR (BooksRun)',
              'None',
            ]}
          />
        );
      case 16:
        return (
          <StepSingleInput
            heading="Wholesale Setup - SellBackYourBooks"
            message="Enter your email address that’s approved for the SellBackYourBook bulk program"
            smallMessageElement={
              <div style={{ margin: '5px 0 15px 0' }}>
                <i>
                  Not currently approved with SBYB?{' '}
                  <a target="_blank" rel="noopener noreferrer" title="SBYB signup link" href="http://scoutiq.co/bulk">
                    Click here to Sign up!
                  </a>
                </i>
              </div>
            }
            allowSkip={true}
            gotoNextScreen={this.gotoNextScreen}
            inputType="text"
            placeholderString="example@gmail.com"
            gotoPreviousScreen={this.gotoPreviousScreen}
            inputVal={this.state.emailSBYB}
            exportVal={(emailSBYB) => this.setState({ emailSBYB })}
          />
        );
      case 17:
        return (
          <StepBinary
            heading="Wholesale Setup"
            message="If the guaranteed offers from the wholesale vendors are a nickel lower than the projected profits from the Amazon estimates, would you send it to the wholesaler instead of risking the sale on Amazon?"
            gotoNextScreen={this.gotoNextScreen}
            gotoPreviousScreen={this.gotoPreviousScreen}
            inputVal={this.state.wholesaleAboveAmazon}
            exportBinary={(wholesaleAboveAmazon) => this.setState({ wholesaleAboveAmazon })}
          />
        );
      case 18:
        return (
          <StepSingleInput
            heading="Wholesale Vendors"
            message="How much lower of a profit would you accept to route it the same way?"
            smallMessage="Most people pick $0.70 as their value"
            gotoNextScreen={this.gotoNextScreen}
            gotoPreviousScreen={this.gotoPreviousScreen}
            inputVal={this.state.wholesaleAboveAmazonMinProfit}
            exportVal={(wholesaleAboveAmazonMinProfit) => this.setState({ wholesaleAboveAmazonMinProfit })}
          />
        );
      case 19:
        return (
          <StepMultipleChoices
            gotoNextScreen={this.gotoNextScreen}
            gotoPreviousScreen={this.gotoPreviousScreen}
            heading="Which BookScouter vendors would you like to see?"
            exportUpdatedList={this.updateBSVendorsList}
            checkedList={this.state.activeBSVendorsList}
            message=""
            smallMessage="These are not native integrations, so extra work will be required to add these books to each vendor’s carts at the end of each work day."
            options={[
              'None',
              'Textbook Drop',
              'Books2Cash',
              'ValoreBooks',
              'Bookbyte',
              'Buyback101',
              'Sell Books',
              'SellBackBooks',
              'TopDollar4Books',
              'eCampus',
              'RentText',
              'CollegeBooksDirect',
              'TextbookManiac',
              'TextbookRush',
              'TextbookCashback',
              "Powell's",
              'Comic Blessing',
              'BeerMoneyBooks',
              'Bookstores.com',
              'Ziffit',
              'Piggy Books',
              'Empire Text',
            ]}
          />
        );
      case 20:
        return (
          <StepBinary
            heading="Wholesale Vendors"
            message="If the guaranteed offer from the BookScouter vendors are a nickel lower than the projected profits from Amazon… would you send it to the vendor instead of selling it on Amazon?"
            gotoNextScreen={this.gotoNextScreen}
            gotoPreviousScreen={this.gotoPreviousScreen}
            inputVal={this.state.bookscouterAboveAmazon}
            exportBinary={(bookscouterAboveAmazon) => this.setState({ bookscouterAboveAmazon })}
          />
        );
      case 21:
        return (
          <StepSingleInput
            heading="Wholesale Vendors"
            message="How much lower of a profit would you still route it to the BookScouter vendor?"
            gotoNextScreen={this.gotoNextScreen}
            gotoPreviousScreen={this.gotoPreviousScreen}
            inputVal={this.state.bookscouterAboveAmazonMinProfit}
            exportVal={(bookscouterAboveAmazonMinProfit) => this.setState({ bookscouterAboveAmazonMinProfit })}
          />
        );
      case 22:
        return (
          <StepInfo
            gotoNextScreen={this.gotoNextScreen}
            gotoPreviousScreen={this.gotoPreviousScreen}
            infoMessage="Okay, last item on the agenda is to select your minimum profits you'd like for your triggers. Are you ready?"
          />
        );
      case 23:
        return (
          <StepMinProfitInput
            heading="minimum profit required for a book that sells..."
            message="Every day?"
            gotoNextScreen={this.gotoNextScreen}
            gotoPreviousScreen={this.gotoPreviousScreen}
            inputVal1={this.state.minProfitSellEveryday}
            escoreRange="115 - 999"
            binaryVal={this.state.isAlwaysRejectSellEveryday}
            inputLabel1="Min Profit"
            exportVal1={(minProfitSellEveryday) => this.setState({ minProfitSellEveryday })}
            exportBinary={(isAlwaysRejectSellEveryday) => this.setState({ isAlwaysRejectSellEveryday })}
          />
        );
      case 24:
        return (
          <StepMinProfitInput
            heading="minimum profit required for a book that sells..."
            message="A Few times a week?"
            gotoNextScreen={this.gotoNextScreen}
            gotoPreviousScreen={this.gotoPreviousScreen}
            inputVal1={this.state.minProfitSellFewTimesAWeek}
            escoreRange="46 - 114"
            binaryVal={this.state.isAlwaysRejectSellFewTimesAWeek}
            inputLabel1="Min Profit"
            exportVal1={(minProfitSellFewTimesAWeek) => this.setState({ minProfitSellFewTimesAWeek })}
            exportBinary={(isAlwaysRejectSellFewTimesAWeek) => this.setState({ isAlwaysRejectSellFewTimesAWeek })}
          />
        );
      case 25:
        return (
          <StepMinProfitInput
            heading="minimum profit required for a book that sells..."
            message="Once a week?"
            gotoNextScreen={this.gotoNextScreen}
            gotoPreviousScreen={this.gotoPreviousScreen}
            inputVal1={this.state.minProfitSellOnceAWeek}
            escoreRange="25 - 45"
            binaryVal={this.state.isAlwaysRejectSellOnceAWeek}
            inputLabel1="Min Profit"
            exportVal1={(minProfitSellOnceAWeek) => this.setState({ minProfitSellOnceAWeek })}
            exportBinary={(isAlwaysRejectSellOnceAWeek) => this.setState({ isAlwaysRejectSellOnceAWeek })}
          />
        );
      case 26:
        return (
          <StepMinProfitInput
            heading="minimum profit required for a book that sells..."
            message="Every other week?"
            gotoNextScreen={this.gotoNextScreen}
            gotoPreviousScreen={this.gotoPreviousScreen}
            inputVal1={this.state.minProfitSellEveryOtherAWeek}
            escoreRange="13 - 24"
            binaryVal={this.state.isAlwaysRejectSellEveryOtherAWeek}
            inputLabel1="Min Profit"
            exportVal1={(minProfitSellEveryOtherAWeek) => this.setState({ minProfitSellEveryOtherAWeek })}
            exportBinary={(isAlwaysRejectSellEveryOtherAWeek) => this.setState({ isAlwaysRejectSellEveryOtherAWeek })}
          />
        );
      case 27:
        return (
          <StepMinProfitInput
            heading="minimum profit required for a book that sells..."
            message="Once a month?"
            gotoNextScreen={this.gotoNextScreen}
            gotoPreviousScreen={this.gotoPreviousScreen}
            inputVal1={this.state.minProfitSellOnceAMonth}
            escoreRange="6 - 12"
            binaryVal={this.state.isAlwaysRejectSellOnceAMonth}
            inputLabel1="Min Profit"
            exportVal1={(minProfitSellOnceAMonth) => this.setState({ minProfitSellOnceAMonth })}
            exportBinary={(isAlwaysRejectSellOnceAMonth) => this.setState({ isAlwaysRejectSellOnceAMonth })}
          />
        );
      case 28:
        return (
          <StepMinProfitInput
            heading="minimum profit required for a book that sells..."
            message="Every 2-3 months?"
            gotoNextScreen={this.gotoNextScreen}
            gotoPreviousScreen={this.gotoPreviousScreen}
            inputVal1={this.state.minProfitSellEvery2or3Months}
            escoreRange="3 - 5"
            binaryVal={this.state.isAlwaysRejectSellEvery2or3Months}
            inputLabel1="Min Profit"
            exportVal1={(minProfitSellEvery2or3Months) => this.setState({ minProfitSellEvery2or3Months })}
            exportBinary={(isAlwaysRejectSellEvery2or3Months) => this.setState({ isAlwaysRejectSellEvery2or3Months })}
          />
        );
      case 29:
        return (
          <StepMinProfitInput
            heading="minimum profit required for a book that sells..."
            message="Once every 6 months?"
            gotoNextScreen={this.gotoNextScreen}
            gotoPreviousScreen={this.gotoPreviousScreen}
            inputVal1={this.state.minProfitSellEvery6Months}
            escoreRange="1 - 2"
            binaryVal={this.state.isAlwaysRejectSellEvery6Months}
            inputLabel1="Min Profit"
            exportVal1={(minProfitSellEvery6Months) => this.setState({ minProfitSellEvery6Months })}
            exportBinary={(isAlwaysRejectSellEvery6Months) => this.setState({ isAlwaysRejectSellEvery6Months })}
          />
        );
      case 30:
        return (
          <StepMinProfitInput
            heading="minimum profit required for a book that sells..."
            message="Once a year?"
            gotoNextScreen={this.gotoNextScreen}
            gotoPreviousScreen={this.gotoPreviousScreen}
            inputVal1={this.state.minProfitSellOnceAYear}
            escoreRange="0 - 0"
            binaryVal={this.state.isAlwaysRejectSellOnceAYear}
            inputLabel1="Min Profit"
            exportVal1={(minProfitSellOnceAYear) => this.setState({ minProfitSellOnceAYear })}
            exportBinary={(isAlwaysRejectSellOnceAYear) => this.setState({ isAlwaysRejectSellOnceAYear })}
          />
        );

      case 31:
        return (
          <StepSingleInput
            heading="If there are no offers on a book, what would you like to set the target list price to?"
            message="Most people use $49 as their standard price"
            gotoNextScreen={this.gotoNextScreen}
            gotoPreviousScreen={this.gotoPreviousScreen}
            inputVal={this.state.targetPriceNoOffers}
            exportVal={(targetPriceNoOffers) => this.setState({ targetPriceNoOffers })}
          />
        );

      // case 32:
      //   return (
      //     <StepAmazonCredentials
      //       heading="Link your amazon account"
      //       message=""
      //       smallMessageElement={
      //         <div style={{ margin: "5px 0 15px 0" }}>
      //           <i>
      //             <a
      //               target="_blank"
      //               rel="noopener noreferrer"
      //               title="SBYB signup link"
      //               href="https://sellercentral.amazon.com/gp/mws/registration/register.html/133-1576208-6497101?devAuth=1&devMWSAccountId=5375-9229-7183&developerName= PalletIQ&ie=UTF8&signInPageDisplayed=1"
      //             >
      //               Click here to retrieve your MWS credentials
      //             </a>
      //           </i>
      //         </div>
      //       }
      //       validateMWSAndGotoNextScreen={this.validateMWSAndGotoNextScreen}
      //       gotoNextScreen={this.gotoNextScreen}
      //       gotoPreviousScreen={this.gotoPreviousScreen}
      //       inputVal1={this.state.mwsSellerId}
      //       inputType1="text"
      //       inputVal2={this.state.mwsAuthToken}
      //       inputType2="text"
      //       inputLabel1="MWS Seller ID"
      //       placeholderString1="e.g. A4K8SWOOQE6EL9"
      //       placeholderString2="e.g. amzn.mws.84a46bg2-d7be-6d52-68fd-de2d5c46f319"
      //       inputLabel2="MWS Auth Token"
      //       exportVal1={(mwsSellerId) => this.setState({ mwsSellerId })}
      //       exportVal2={(mwsAuthToken) => this.setState({ mwsAuthToken })}
      //       validatingMWS={this.state.validatingMWS}
      //       errorMWS={this.state.errorMWS}
      //     />
      //   );

      case 32:
        return (
          <StepPassword
            userPassword={this.state.userPassword}
            userPassword2={this.state.userPassword2}
            signupInProgres={this.state.signupInProgress}
            exportPassword={(userPassword) => this.setState({ userPassword })}
            exportPassword2={(userPassword2) => this.setState({ userPassword2 })}
            gotoNextScreen={this.finishSignup}
            gotoPreviousScreen={this.gotoPreviousScreen}
          />
        );

      case 33:
        return (
          <Elements stripe={stripePromise}>
            <StepPayment
              smallMessage="Add a credit card to your account"
              userId={this.state.user_id}
              adminToken={this.state.appToken}
              gotoNextScreen={this.gotoNextScreen}
              gotoPreviousScreen={this.gotoPreviousScreen}
              cardErrorMessage={this.state.cardErrorMessage}
              updateCardErrorMessage={(cardErrorMessage) => {
                this.setState({ cardErrorMessage });
              }}
            />
          </Elements>
        );

      default:
        return (
          <StepInfo
            gotoNextScreen={this.gotoNextScreen}
            gotoPreviousScreen={this.gotoPreviousScreen}
            lastStep={true}
            infoMessage="You did it! Give us a few minutes while we build your account to your specifications. We'll have you up and scanning in no time."
            infoMessageSmall={'You can add new users through your PalletIQ admin dashboard.'}
            smallMessageElement={
              <div style={{ margin: '5px 0 15px 0' }}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  title="SBYB signup link"
                  href={`https://admin.palletiq.com/account-settings?token=${this.state.appToken}`}
                >
                  Click here to visit the admin dashboard
                </a>
              </div>
            }
          />
        );
    }
  }

  render() {
    return (
      <React.Fragment>
        <Container fluid={true} style={{ textAlign: 'center' }}>
          {this.state.validatingToken === true ? (
            <div style={{ marginTop: '100px' }}>
              <Spinner animation="border" role="status"></Spinner>
            </div>
          ) : this.state.validatingToken === false && this.state.invalidToken === false ? (
            <div style={{ paddingBottom: '100px' }}>
              <div style={{ width: '980px', maxWidth: '100%', margin: '0 auto' }}>
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                  <img
                    style={{ width: `${logoWidth}px` }}
                    alt="logo"
                    className="logo-home"
                    src={require(`../assets/images/PIQ/logo_login.png`)}
                  />
                </div>

                <div style={{ marginTop: '50px' }}>
                  <div style={{ minHeight: '300px' }}>{this.getCurrentStep()}</div>

                  <div style={{ marginTop: '50px' }}>
                    <ProgressBar
                      style={{ height: '10px', borderRadius: '0' }}
                      now={(100 / this.state.totalSteps) * this.state.currentStep}
                    />
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'center' }}>
                <div
                  className={`onboardingBreadCrumbs ${this.state.currentStep >= 1 && 'active-bc'}`}
                  style={{ marginLeft: '0' }}
                  onClick={() => this.state.filledSteps >= 1 && this.setState({ currentStep: 1 })}
                >
                  Intro &#187;
                </div>
                <div
                  className={`onboardingBreadCrumbs ${this.state.currentStep >= 2 && 'active-bc'}`}
                  onClick={() => this.state.filledSteps >= 1 && this.setState({ currentStep: 2 })}
                >
                  Team Info &#187;
                </div>
                <div
                  className={`onboardingBreadCrumbs ${this.state.currentStep >= 3 && 'active-bc'}`}
                  onClick={() => this.state.filledSteps >= 2 && this.setState({ currentStep: 2 })}
                >
                  Triggers &#187;
                </div>
                <div
                  className={`onboardingBreadCrumbs ${this.state.currentStep >= 9 && 'active-bc'}`}
                  onClick={() => this.state.filledSteps >= 8 && this.setState({ currentStep: 8 })}
                >
                  Expenses &#187;
                </div>
                <div
                  className={`onboardingBreadCrumbs ${this.state.currentStep >= 12 && 'active-bc'}`}
                  onClick={() => this.state.filledSteps >= 11 && this.setState({ currentStep: 11 })}
                >
                  Wholesale &#187;
                </div>
                <div
                  className={`onboardingBreadCrumbs ${this.state.currentStep >= 22 && 'active-bc'}`}
                  onClick={() => this.state.filledSteps >= 18 && this.setState({ currentStep: 21 })}
                >
                  Profit &#187;
                </div>
                <div
                  className={`onboardingBreadCrumbs ${this.state.currentStep >= 30 && 'active-bc'}`}
                  onClick={() => this.state.filledSteps >= 18 && this.setState({ currentStep: 30 })}
                >
                  Amazon &#187;
                </div>
                <div
                  className={`onboardingBreadCrumbs ${this.state.currentStep >= 34 && 'active-bc'}`}
                  onClick={() => this.state.filledSteps >= 18 && this.setState({ currentStep: 34 })}
                >
                  Billing
                </div>
              </div>
            </div>
          ) : (
            <div style={{ marginTop: '100px' }}>
              <h3 style={{ color: 'red' }}>Invalid Token</h3>
            </div>
          )}
        </Container>
      </React.Fragment>
    );
  }
}

export default Signup;
