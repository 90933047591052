import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import auth from './Auth';
import { Spinner } from 'react-bootstrap';

class ProtectedRoute extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isLoading: true,
      isLoggedIn: false,
    };

    this.validateToken();
  }

  async validateToken() {
    const isAuthenticated = await auth.isAuthenticated();
    console.log({ isAuthenticated });
    this.setState(() => ({ isLoading: false, isLoggedIn: isAuthenticated }));
  }

  render() {
    return this.state.isLoading ? (
      <div style={{ textAlign: 'center', fontSize: '34px', marginTop: '50px' }}>
        <Spinner
          animation="border"
          role="status"
          style={{ marginRight: '10px', width: '40px', height: '40px' }}
        ></Spinner>{' '}
        Loading ...
      </div>
    ) : this.state.isLoggedIn ? (
      <Route path={this.props.path} component={this.props.component} exact={this.props.exact} />
    ) : (
      <Redirect to={{ pathname: '/', state: { from: this.props.location } }} />
    );
  }
}

export default ProtectedRoute;
