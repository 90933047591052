import React, { Component } from 'react';
import { apiBase } from '../Config';
import { Modal, Button } from 'react-bootstrap';

class ModalReconnectCredentials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdatingSPAPICode: false,
      // updateNotes: [],
    };
    // this.handleOpen = this.handleOpen.bind(this);
  }

  async initiate() {
    console.log(`Reading parameters ...`);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    const spApiOAuthCode = params.get('spapi_oauth_code');
    const sellerId = params.get('selling_partner_id');

    if (spApiOAuthCode) {
      this.setState({ isUpdatingSPAPICode: true });

      try {
        const response = await fetch(apiBase + 'user/sp-auth/' + spApiOAuthCode, {
          method: 'PUT',
          body: new URLSearchParams({
            user_id: this.props.userId,
            app_token: this.props.userToken,
            seller_id: sellerId,
          }),
        });
        await response.json();
        if (response.status === 200) {
          window.location.reload();
        }
      } catch (error) {
        console.log(error);
      }
    }

    this.setState({ isUpdatingSPAPICode: false });
  }

  componentDidMount() {
    // this.initiate();
  }

  render() {
    return (
      <Modal
        style={{ zIndex: 999999 }}
        backdrop="static"
        keyboard={false}
        // onEntered={this.handleOpen}
        show={this.props.showReconnectModal}
        onHide={this.props.handleClose}
        dialogClassName="editModal"
      >
        <Modal.Header>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <h3>Pardon the interruption</h3>
          </div>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }}>
          <div style={{ fontSize: '13.5px' }}>
            We've updated our software to work with the most recent Amazon APIs. To comply with the new regulations,
            we'll need you to re-connect your Amazon MWS with us. This is a one-time process, and the changes take
            effect instantaneously. The new update will also be able to identify restricted items for your Amazon Seller
            Central.
          </div>

          <div style={{ marginTop: '15px' }}>
            <p style={{ fontSize: '13px', fontWeight: 'bold' }}>Please login to the admin portal, Click on Settings</p>

            <div style={{ marginTop: '15px' }}>
              <Button
                disabled={this.state.isUpdatingSPAPICode}
                variant="success"
                onClick={() => {
                  window.open(
                    'https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sellerapps.app.b675694d-3f7b-4ec6-bb14-2f135560bda7&state=REPRICEIQ&version=beta&redirect_uri=https://admin.palletiq.com',
                    '_self',
                    'noopener,noreferrer'
                  );
                }}
              >
                Connect your Amazon Account
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ModalReconnectCredentials;
